<!--
 * @Author: CL
 * @Date: 2021-06-22 10:47:09
 * @LastEditTime: 2021-07-12 22:03:39
 * @Description: 登录页
-->

<template>
  <div class="login-contain">
    <CarouseItem :info="info"/>

    <!-- 登录表单 -->
    <div class="form-wrap">
      <div class="form-item">
        <label for="name">
          <span>账号：</span>
          <input maxlength="16" placeholder="请输入您的账号" type="text" id="name" autocomplete="off" v-model.trim="ruleForm.loginId">
        </label>
        <div class="err">{{ errInfo.name }}</div>
      </div>
      <div class="form-item">
        <label for="password">
          <span>密码：</span>
          <input maxlength="16" placeholder="请输入您的密码" type="password" id="password" v-model.trim="ruleForm.loginPwd">
        </label>
        <div class="err">{{ errInfo.password }}</div>
      </div>
      <!-- <div class="form-item vcode">
        <label for="verifycode">
          <input autocomplete="off" v-model.trim="ruleForm.verifyCode" type="text" placeholder="请输入验证码" id="verifycode">
          <span @click="changeCode" v-html="verifyCode.data"></span>
        </label>
        <div class="err">{{ errInfo.verify }}</div>
      </div> -->
      <button class="submit" :disabled="loading" @click="handleSubmit">{{ loading? '登录中...' : '登录' }}</button>
    </div>
  </div>
</template>

<script>
import CarouseItem from '../Img/components/CarouseItem.vue';
import img from '@/assets/t1.jpg';
import { mapState } from 'vuex';

export default {
  data(){
    return {
      info: {
        bigImg: img,
        smallImg: img,
        title: '',
        subTitle: '',
        self: true
      },

      //表单信息
      ruleForm: {
        loginId: '',
        loginPwd: '',
        verifyCode: '',  //验证码
      },
      
      //错误信息
      errInfo: {
        name: '',
        password: '',
        verify: ''
      },
    }
  },

  methods: {
    /**
     * 登录
     */
    async handleSubmit(){
      //如果正在加载中，不进行任何操作
      if(this.loading) return;
      this.errInfo.name = this.ruleForm.loginId? '' : '请输入账号';
      this.errInfo.password = this.ruleForm.loginPwd? '' : '请输入密码';
      // this.errInfo.verify = this.ruleForm.verifyCode? '' : '请输入验证码';
      if(this.errInfo.name || this.errInfo.password) return;

      const result = await this.$store.dispatch('loginUser/login', this.ruleForm);
      if(result.code == 200){
        const user = JSON.parse(localStorage.getItem('user'));
        const mes = user.loginId? `欢迎小主人${user.loginId}` : '登录成功';
        this.$showMessage({
          message: mes,
          type: 'success',
        })
        //登录成功跳转到后台管理页面的首页
        const path = this.$route.query.returnurl || '/manage';
        this.$router.push({ path });
      }else{
        // this.$store.dispatch('loginUser/getVerifyCode');
        this.$showMessage({
          message: result.msg,
          type: 'error'
        })
      }
    },

    changeCode(){
      this.$store.dispatch('loginUser/getVerifyCode');
    }
  },

  created(){
    //执行请求验证码
    // this.$store.dispatch('loginUser/getVerifyCode');
  },

  computed: {
    ...mapState('loginUser', ['loading', 'verifyCode'])
  },

  components: {
    CarouseItem
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';
@import '~@/styles/var.less';

.login-contain{
  .self-fill(fixed);

  .form-wrap{
    width: 380px;
    padding: 40px 10px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: linear-gradient(125deg, rgba(255, 255, 255, 0.5), rgba(222, 222, 222, 0.5), rgba(190, 190, 190, 0.5), rgba(150, 150, 150, 0.5));
    background-size: 400%;   
    animation: move 8s infinite;

    .form-item{
      margin: 0 0 15px 0;

      .err{
        font-size: 12px;
        color: @danger;
        margin-top: 4px;
        height: 20px;
      }

      &.vcode{

        > label{
          display: flex;
          align-items: center;
          input{
            width: 170px;
            height: 40px;
          }

          span{
            margin-left: 4px;
            display: inline-block;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
    
    label{
      display: flex;
      align-items: center;
      margin: 0 0 0px 0;

      span{
        font-size: 16px;
      }

      input{
        width: 250px;
        height: 40px;
        padding: 0 8px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.6);
        border: none;
        outline: none;
      }
    }

    .submit{
      width: 100px;
      text-align: center;
      line-height: 26px;
      font-size: 16px;
      background-image:linear-gradient(125deg,#2c3e50,#27ae60,#2980b9,#0080c0, #8e44ad, orange);
      background-size: 400%;   
      animation: move 8s infinite;
      color: #fff;
      padding: 6px 0;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      border: none;
    }
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
